<template>
  <div 
    :key="categoryId" 
    id="public-category" 
    class="padding-btm-channel-link"
    v-loading="isLoadingDefault || isLoadingMore"
  >
    <template v-if="defaultRows.length == 0 && !isLoadingDefault">
      <div class="coming-soon">{{ $t("COMING_SOON") }}</div>
    </template>

    <div v-else v-loading="isLoadingDefault || isLoadingMore">
      <el-row class="highlight-articles" :type="isMobile ? '' : 'flex'" justify="space-between" :gutter="10">
        <el-col>
          <article-summary 
            v-if="defaultRows[0]"
            orientation="portrait"
            size="big" 
            :articleObj="defaultRows[0]"
            withWhiteBg withShadow
            hideName
            clickable
            showLikeDislikeSection
          />
        </el-col>
        <el-col class="right-articles" :span="isMobile? 24 : 6">
          <article-summary 
            v-for="el in defaultRows.slice(1, 3)"
            :key="el.id"
            orientation="portrait"
            size="small" 
            :articleObj="el"
            withWhiteBg withShadow
            clickable
            hideName
            hideDescription
            showLikeDislikeSection
          />
        </el-col>
      </el-row>

      <el-row class="more-articles" :type="isMobile ? '' : 'flex'" :gutter="20">
        <el-col
          v-for="el in defaultRows.slice(3, 7)"
          :key="el.id"
          :span="isMobile? 24 : 6"
        >
          <article-summary 
            orientation="portrait"
            size="small"
            :articleObj="el"
            withWhiteBg
            clickable
            hideName
            withShadow
            showLikeDislikeSection
          />
        </el-col>
      </el-row>

      <el-row class="more-articles" :type="isMobile ? '' : 'flex'" :gutter="20">
        <el-col
          v-for="el in defaultRows.slice(7, 11)"
          :key="el.id"
          :span="isMobile? 24 : 6"
        >
          <article-summary 
            orientation="portrait"
            size="small"
            :articleObj="el"
            withWhiteBg
            clickable
            hideName
            withShadow
            showLikeDislikeSection
          />
        </el-col>
      </el-row>

      <transition-group name="row">
        <el-row 
          class="more-articles" 
          :type="isMobile ? '' : 'flex'"           
          :gutter="20"
          v-for="(row, index) in extraRows"
          :key="'row_' + index"
        >
          <el-col :span="isMobile? 24 : 6" v-for="el in row" :key="el.id">
            <article-summary 
              orientation="portrait"
              size="small"
              :articleObj="el"
              withWhiteBg withShadow
              clickable
              hideName
              showLikeDislikeSection
            />
          </el-col>
        </el-row>
      </transition-group>

      <div id="page-bottom-to-load-more"/>
      <template v-if="totalAvailable === totalLoaded" >
        <br><br>
        <el-divider class="last-line">{{ $t("NEWS_END_MSG")}}</el-divider>
        <br>
      </template>
    </div>

    <el-row type="flex" justify="center" v-loading="isLoadingMore">
      <span 
        v-if="totalAvailable > totalLoaded && !isLoadingDefault"
        :class="{ pointer: !isLoadingMore }"
        class="text-underline text-black text-bold load-more" 
        @click="loadMore()"
      >{{ $t("LOAD_MORE") }}</span>
    </el-row>


  </div>
</template>

<script>
import ArticleSummary from "./components/article-summary.vue"
import { mapState } from "vuex";
import { getCategoryById } from "@/api/category"
import { getArticleList } from "@/api/article"
import { splitArrayToChunk } from "@/utils/helpers"
import { generalMixin } from "@/utils/general-mixin.js"
import { EventBus } from "@/utils/event-bus";

export default {
  name: "ArticleByCategory",
  components: {
    ArticleSummary,
  },
  computed: {
    ...mapState("app", ["isMobile"]),
  },
  mixins: [ generalMixin ],
  data() {
    return {
      categoryId: null,
      category: {},
      order: "desc",
      defaultRows: [],
      extraRows: [],
      totalLoaded: 0,
      totalAvailable: 1,
      isLoadingMore: false,
      isLoadingDefault: false,
    }
  },
  watch: {
    '$route.params.categoryId': {
      handler() {
        this.mountedFn()
      }
    }
  },
  methods: {
    getCategory() {
      if (this.categoryId != null) {
        getCategoryById(this.categoryId).then(res => {
          this.category = res.result
        })
      } else {
        this.category = {}
      }
    },
    async getArticle(amount = 1) {
      let offset = this.totalLoaded
      const postData = {
        offset,
        limit: amount,
        desc: true,
        published: true,
        orderCol: "publish_date",
        categoryActiveOnly: true,
        statusList: ["ACTIVE"]
      }
      if (this.categoryId != null) {
        postData.category = { id: this.categoryId }
      }
      let result = []
      await getArticleList(postData).then(res => {
        if (res.result?.list != null) {
          result = res.result.list
          this.totalAvailable = res.result.total
          this.totalLoaded += result.length
        }
      })
      return result
    },
    // onScroll: debounce(function () {
    //   const tolerance = 900 // load before reaching
    //   const idYPosition = document.getElementById("page-bottom-to-load-more").getBoundingClientRect().top;
    //   if (idYPosition - tolerance < 0) {
    //     if (this.totalAvailable > this.totalLoaded && !this.isLoadingDefault) {
    //       this.loadMore()
    //     }
    //   }
    // }, 100),
    async loadMore() {
      if (this.isLoadingMore) return
      const loadRowNum = 2

      this.isLoadingMore = true
      let result = await this.getArticle(loadRowNum * 4)
      this.isLoadingMore = false

      splitArrayToChunk(result, 4).forEach(row => {
        this.extraRows.push(row)
      })
    },
    reset() {
      this.defaultRows = []
      this.extraRows = []
      this.totalAvailable = 1
      this.totalLoaded = 0
    },
    async mountedFn() {
      this.reset()
      this.categoryId = this.$route.params.categoryId
      this.getCategory()
      this.isLoadingDefault = true
      this.defaultRows = await this.getArticle(11)
      this.isLoadingDefault = false
    }
  },
  mounted() {
    this.mountedFn();
    (EventBus || this.$EventBus).$on("on-language-change", () => this.mountedFn());
    // window.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy() {
    (EventBus || this.$EventBus).$off("on-language-change");
    // window.removeEventListener('scroll', this.onScroll)
  },
}
</script>

<style lang="scss">
@import "@/assets/style/_variables.scss";

#public-category {
  .coming-soon {
    font-size: 2rem;
    font-weight: bold;
    margin: auto;
    height: 25vh;
    width: 100%;
    width: 100vw;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
  }
  .category-name-row {
    span {
      margin: auto;
      white-space: nowrap;
      min-height: 1.75rem;
    }
    .el-divider {
      margin: 1.7rem 0.5rem;
    }
  }
  .highlight-articles {
    margin: auto 10px 10px auto;
  }
  .right-articles {
    display: flex;
    flex-direction: column;
    >* {
      height: fit-content;
      &:first-child {
        margin-bottom: 10px;
      }
    }
  }
  .more-articles {
    margin: 20px auto;
    .small .summary-detail {
      height: 8.5rem;
    }
  }
  .load-more {
    font-size: 18px;
    line-height: 98px;
  }

  // Transition
  .row-enter-active, .row-leave-active {
    transition: all 1s;
  }
  .row-enter, .row-leave-to /* .row-leave-active below version 2.1.8 */ {
    opacity: 0;
    transform: translateY(30px);
  }
  .last-line > div {
    background: #f4f8fb;
    font-weight: bold;
    font-family: 'SourceSansPro-Bold';
  }
}

.mobile {
  #public-category {
    .article-summary {
      margin-bottom: 10px;
    }
    .more-articles {
      margin-bottom: 0;
    }
  }
}
</style>